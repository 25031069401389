import * as React from "react";
import { MapAddress, MapLeftContact, MapProvince } from "@brainfinance/icash-component-library";
import { MapContactProps, MapProvinceProps } from "./map";
//Images
import bgContact from "@images/customer-service.png";

export const MapContact = (props:MapContactProps) => (
  <div className="map--contact-container">
    <MapLeftContact title={props.title} style={{ backgroundImage: `url(${props.image})` }}>
      Our Customer Experience Advisors are here to help you!
      <br /><br />
      Monday to Friday 24 hours a day.
      <br />
      Saturday and Sunday from 7 am to midnight. Easter Standard time.
      <br /><br />
      That's right! iCASH is available throughout the whole day for their customers because we
      strive to provide an amazing Customer Service!
      <br /><br />
      The best way to contact us is by email at support@icash.ca.
      <br /><br />
      Let us help you!
    </MapLeftContact>
    <MapAddress mapLink={props.mapLink}>
      372 Bertha Street,
      <br />
      Hawkesbury, Ontario,
      <br />
      K6A 2A8
    </MapAddress>
  </div>
);

MapContact.defaultProps = {
  title: "Opening hours",
  image: bgContact,
  mapLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2791.1301735126835!2d-74.59703818443772!3d45.608027979102864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccee8b9e4f14c11%3A0xdfc99d632f99e96a!2s372+Bertha+St%2C+Hawkesbury%2C+ON+K6A+2A8!5e0!3m2!1sen!2sca!4v1527689423422"
}

export const MapProvinceBox = (props: React.PropsWithChildren<MapProvinceProps>) => (
  <MapProvince {...props}>
    {props.children}
  </MapProvince>
);

MapProvinceBox.defaultProps = {
  title: "Best things about Alberta, Canada:",
  image: bgContact,
  videoLink: "https://www.youtube.com/embed/aNgPKawdaRY",
  mapLink:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9567464.124442505!2d-123.95140472101707!3d54.1657882489888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5309b282a82419b5%3A0xb0a9479a409b8e9e!2sAlberta!5e0!3m2!1sen!2sca!4v1539614465967"
}
