export const SEGMENTEDDATA = [
  {
    label: "How to be Eligible for a Loan",
    text:
      "To qualify for a fast online loan from iCASH, you must:\n\na. Be at least 19 years old.\n\nb. Be a Canadian citizen and a resident of Alberta, British Columbia, New Brunswick, Ontario, Manitoba, Nova Scotia, or Prince Edward Island.\n\nc. Be employed with the same employer for the past 3 months.\n\nd. Have a net income of at least $800/month.\n\ne. Recieve your pay by direct deposit.\n\nf. Have a Canadian bank account that is open for at least 3 months.\n\ng. Have a valid mobile phone, email address and home address.\n\nh. Not have filed in the past of have the intention on filing for bankruptcy.",
  },
  {
    label: "Interest fees & transparency",
    text:
      "To qualify for a fast online loan from iCASH, you must:\n\na. Be at least 19 years old.\n\nb. Be a Canadian citizen and a resident of Alberta, British Columbia, New Brunswick, Ontario, Manitoba, Nova Scotia, or Prince Edward Island.\n\nc. Be employed with the same employer for the past 3 months.\n\nd. Have a net income of at least $800/month.\n\ne. Recieve your pay by direct deposit.\n\nf. Have a Canadian bank account that is open for at least 3 months.\n\ng. Have a valid mobile phone, email address and home address.\n\nh. Not have filed in the past of have the intention on filing for bankruptcy.",
  },
  {
    label: "Getting your money",
    text:
      "To qualify for a fast online loan from iCASH, you must:\n\na. Be at least 19 years old.\n\nb. Be a Canadian citizen and a resident of Alberta, British Columbia, New Brunswick, Ontario, Manitoba, Nova Scotia, or Prince Edward Island.\n\nc. Be employed with the same employer for the past 3 months.\n\nd. Have a net income of at least $800/month.\n\ne. Recieve your pay by direct deposit.\n\nf. Have a Canadian bank account that is open for at least 3 months.\n\ng. Have a valid mobile phone, email address and home address.\n\nh. Not have filed in the past of have the intention on filing for bankruptcy.",
  },
  {
    label: "Managing your loan",
    text:
      "To qualify for a fast online loan from iCASH, you must:\n\na. Be at least 19 years old.\n\nb. Be a Canadian citizen and a resident of Alberta, British Columbia, New Brunswick, Ontario, Manitoba, Nova Scotia, or Prince Edward Island.\n\nc. Be employed with the same employer for the past 3 months.\n\nd. Have a net income of at least $800/month.\n\ne. Recieve your pay by direct deposit.\n\nf. Have a Canadian bank account that is open for at least 3 months.\n\ng. Have a valid mobile phone, email address and home address.\n\nh. Not have filed in the past of have the intention on filing for bankruptcy.",
  },
  {
    label: "Repaying your loan",
    text:
      "To qualify for a fast online loan from iCASH, you must:\n\na. Be at least 19 years old.\n\nb. Be a Canadian citizen and a resident of Alberta, British Columbia, New Brunswick, Ontario, Manitoba, Nova Scotia, or Prince Edward Island.\n\nc. Be employed with the same employer for the past 3 months.\n\nd. Have a net income of at least $800/month.\n\ne. Recieve your pay by direct deposit.\n\nf. Have a Canadian bank account that is open for at least 3 months.\n\ng. Have a valid mobile phone, email address and home address.\n\nh. Not have filed in the past of have the intention on filing for bankruptcy.",
  },
  {
    label: "Repeat borrowing",
    text:
      "To qualify for a fast online loan from iCASH, you must:\n\na. Be at least 19 years old.\n\nb. Be a Canadian citizen and a resident of Alberta, British Columbia, New Brunswick, Ontario, Manitoba, Nova Scotia, or Prince Edward Island.\n\nc. Be employed with the same employer for the past 3 months.\n\nd. Have a net income of at least $800/month.\n\ne. Recieve your pay by direct deposit.\n\nf. Have a Canadian bank account that is open for at least 3 months.\n\ng. Have a valid mobile phone, email address and home address.\n\nh. Not have filed in the past of have the intention on filing for bankruptcy.",
  },
  {
    label: "Late fees",
    text: "Small text",
  },
];