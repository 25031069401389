import * as React from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

// Images
import applyOnlineImg from "@images/flow-presentation-img/mobile-loans.png";
import instantDecisionImg from "@images/flow-presentation-img/mobile-loan-approved.png";
import getFundsImg from "@images/flow-presentation-img/mobile-money-sent.png";

// Components
import { Box } from "@components/box";
import { PageLayout, SEO } from "@components/page-layout";
import {
  GrayPhrase,
  Heading,
  PageHeading,
  Paragraph,
  Subheading,
} from "@components/typography";
import { MainHeaderContainer } from "@containers/header";
import { StatisticsList } from "@containers/statistics";
import { FlowPresentationSection } from "@containers/flow-presentation";
import { Paragraphs } from "@containers/paragraphs";
import { GatsbyLink } from "@components/gatsby-link";
import { FullscreenSection } from "@containers/fullscreen";
import { SegmentedContentSection } from "@containers/segmented-content";
import { CompareTableSection } from "@containers/compare-table";
import { PartnersSection } from "@containers/partners";
import { MapProvinceBox } from "@containers/map";
import { FlowPresentationCard, ReadMore, ReadMoreSidePanel, ReadMoreText } from "@brainfinance/icash-component-library";
import { SEGMENTEDDATA } from "@containers/segmented-content/segmented-content-data";
import arrow from "@images/ic-arrow-long-right.svg";

const ProvinceCityTemplate = (props: any) => {
  const { contentfulProvinceCity }: any = props.data;
  const renderLongText = (content: string) => {
    // Create a text with links with the good styles and props
    return (
      <ReactMarkdown
        components={{
          a: ({ node, ...props }) => {
            if (props.href)
              return (
                <GatsbyLink
                  to={props.href}
                  rel={
                    props.href.startsWith("https://icash.ca")
                      ? "noopener"
                      : "noopener nofollow"
                  }
                >
                  {props.children}
                </GatsbyLink>
              );
            else return null;
          },
          p: ({node, ...props}) => <p className="mb-[15px] last:mb-0" {...props}/>
        }}
        children={content}
      />
    );
  };

  const generateTitle = (title: string, gray?: string) => {
    if (gray) {
      if (title.includes(gray)) {
        const titleArray = title.split(gray);
        return (
          <>
            <GrayPhrase>{titleArray[0]}</GrayPhrase>
            {gray}
            <br />
            <GrayPhrase>{titleArray[1]}</GrayPhrase>
          </>
        );
      }
    }

    return { title };
  };

  const youtubeLink = (link: string) => {
    const splitLink = new URL(link).pathname;
    return "https://youtube.com/embed"+splitLink ;
  }

  const stepsData = [
    {
      step: 1,
      title: contentfulProvinceCity.step1title,
      children: contentfulProvinceCity.step1Text.step1Text,
      image: applyOnlineImg,
    },
    {
      step: 2,
      title: contentfulProvinceCity.step2Title,
      children: contentfulProvinceCity.step2Text.step2Text,
      image: instantDecisionImg,
    },
    {
      step: 3,
      title: contentfulProvinceCity.step3Title,
      children: contentfulProvinceCity.step3Text.step3Text,
      image: getFundsImg,
    },
  ];
  const contentData = [
    {
      title: contentfulProvinceCity.firstParagrapheTitle,
      description: renderLongText(
        contentfulProvinceCity.firstParagrapheText.firstParagrapheText
      ),
    },
    {
      title: contentfulProvinceCity.secondParagrapheTitle,
      description: renderLongText(
        contentfulProvinceCity.secondParagrapheText.secondParagrapheText
      ),
    },
    {
      title: contentfulProvinceCity.thirdParagrapheTitle,
      description: renderLongText(
        contentfulProvinceCity.thirdParagrapheText.thirdParagrapheText
      ),
    },
    {
      title: contentfulProvinceCity.fourthParagrapheTitle,
      description: renderLongText(
        contentfulProvinceCity.fourthParagrapheText.fourthParagrapheText
      ),
    },
  ];
  const faqData = [
    {
      title: contentfulProvinceCity.question1,
      description: renderLongText(contentfulProvinceCity.answer1.answer1),
    },
    {
      title: contentfulProvinceCity.question2,
      description: renderLongText(contentfulProvinceCity.answer2.answer2),
    },
    {
      title: contentfulProvinceCity.question3,
      description: renderLongText(contentfulProvinceCity.answer3.answer3),
    },
    {
      title: contentfulProvinceCity.question4,
      description: renderLongText(contentfulProvinceCity.answer4.answer4),
    },
    {
      title: contentfulProvinceCity.question5,
      description: renderLongText(contentfulProvinceCity.answer5.answer5),
    },
    {
      title: contentfulProvinceCity.question6,
      description: renderLongText(contentfulProvinceCity.answer6.answer6),
    },
  ];

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const imageID = node.data.target.sys.id;
        const {
          file: { url },
        } = contentfulProvinceCity.body.references.find(
          ({ contentful_id }: { contentful_id: string | number }) =>
            contentful_id === imageID
        );

        return (
          <Box display="flex" className="w-full justify-center p-[2rem]">
            <img className="w-4/5 md:w-full" src={url} />
          </Box>
        );
      },
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <Box className="py-[2rem]">
          <PageHeading>{children}</PageHeading>
        </Box>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <Box className="py-[1.5rem]">
          <Heading>{children}</Heading>
        </Box>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <Box className="py-[1rem]">
          <Subheading>{children}</Subheading>
        </Box>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <Box className="py-[0.75rem]">
          <Paragraph className="text-interface-300">{children}</Paragraph>
        </Box>
      ),
      [BLOCKS.UL_LIST]: (_node: any, children: any) => <ul>{children}</ul>,
    },
  };

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  
  return (
    <PageLayout>
      <SEO
        title={contentfulProvinceCity.metaTitle}
        description={contentfulProvinceCity.metaDescription.metaDescription}
      />
      <MainHeaderContainer
        description={contentfulProvinceCity.shortDescription}
        subParagraph={contentfulProvinceCity.shortDescription}
        src={contentfulProvinceCity.headerImage?.gatsbyImageData.images.fallback.src}
        srcSet={contentfulProvinceCity.headerImage?.gatsbyImageData.images.fallback.srcSet}
        mediumImg
      >
        {generateTitle(
          contentfulProvinceCity.h1,
          contentfulProvinceCity.provinceName
        )}
      </MainHeaderContainer>
      <Box
        display="flex"
        className="mx-auto justify-start max-w-[1320px] lg:max-w-[1135px] mt-[2.5rem] mb-[5rem] md:max-w-[540px] sm:mb-[2rem]"
      >
        <ReadMore>
          <ReadMoreText>
            {renderRichText(contentfulProvinceCity.paragraphes, options)}
          </ReadMoreText>
          <ReadMoreSidePanel active={isOpen} onOpen={handleOpen} onClose={handleClose}>
            {renderRichText(contentfulProvinceCity.paragraphes, options)}
          </ReadMoreSidePanel>
        </ReadMore>
      </Box>
      <StatisticsList
        title={contentfulProvinceCity.statisticTitle}
        description={contentfulProvinceCity.statisticText.statisticText}
      />
      <FlowPresentationSection
        title={contentfulProvinceCity.threeStepsTitle}
        gray={contentfulProvinceCity.provinceName}
      >
        {stepsData.map((flowPresentation, ndx) => (
          <FlowPresentationCard key={ndx} {...flowPresentation} />
        ))}
      </FlowPresentationSection>
      <Paragraphs
        title={contentfulProvinceCity.paragraphesMainTitle}
        gray={contentfulProvinceCity.provinceName}
        description={
          contentfulProvinceCity.paragraphesSmallDescription
            .paragraphesSmallDescription
        }
        contentData={contentData}
      />
      <MapProvinceBox
        title={contentfulProvinceCity.titleLinksList}
        image={contentfulProvinceCity.headerImage?.gatsbyImageData.images.fallback.src}
        videoLink={youtubeLink(contentfulProvinceCity.youtubeLink)}
        mapLink={"https://maps.google.com/maps?q="+contentfulProvinceCity.provinceName+"&output=embed"}
      >
        {contentfulProvinceCity.links.map((link:any, key:number) => (
          <ReactMarkdown
            key={key}
            components={{
              a: ({ node, ...props }) => {
                return (
                  <a href={props.href} className="map--link" rel="noopener nofollow">
                    {props.children} <img src={arrow} alt="Link" />
                  </a>
                );
              }
            }}
            children={link}
          />
        ))}
      </MapProvinceBox>
      <FullscreenSection />
      <Paragraphs
        title={contentfulProvinceCity.faqTitle}
        gray={contentfulProvinceCity.faqGrayTitle}
        description={contentfulProvinceCity.faqShortText.faqShortText}
        contentData={faqData}
      />
      <SegmentedContentSection segmentedData={SEGMENTEDDATA} />
      <CompareTableSection />
      <PartnersSection />
    </PageLayout>
  );
};

export default ProvinceCityTemplate;

export const pageQuery = graphql`
  query ProvinceCityQuery($slug: String!) {
    contentfulProvinceCity(slug: { eq: $slug }) {
      youtubeLink
      updatedAt
      titleLinksList
      threeStepsTitle
      thirdParagrapheTitle
      thirdParagrapheText {
        thirdParagrapheText
      }
      step3Title
      step3Text {
        step3Text
      }
      step2Title
      step2Text {
        step2Text
      }
      step1title
      step1Text {
        step1Text
      }
      statisticTitle
      statisticText {
        statisticText
      }
      slug
      shortDescription
      secondParagrapheTitle
      secondParagrapheText {
        secondParagrapheText
      }
      question6
      question5
      question4
      question3
      question2
      question1
      provinceName
      provinceCityMapLink {
        lat
        lon
      }
      paragraphesSmallDescription {
        paragraphesSmallDescription
      }
      paragraphesMainTitle
      paragraphes {
        raw
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      links
      headerImage {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1950, quality: 70)
        title
      }
      h1
      fourthParagrapheTitle
      fourthParagrapheText {
        fourthParagrapheText
      }
      firstParagrapheTitle
      firstParagrapheText {
        firstParagrapheText
      }
      faqTitle
      faqGrayTitle
      faqShortText {
        faqShortText
      }
      cashbackTitle
      cashbackDescriptionText {
        cashbackDescriptionText
      }
      answer6 {
        answer6
      }
      answer5 {
        answer5
      }
      answer4 {
        answer4
      }
      answer3 {
        answer3
      }
      answer2 {
        answer2
      }
      answer1 {
        answer1
      }
    }
  }
`;
